import * as React from 'react'
import css from './MoreOptionsAvailable.scss'
import { IProduct } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/PDP'

export interface IMoreOptionsAvailableProps {
  variantCount: number
  goToProduct: (product: IProduct) => void
  product: IProduct
}

const MoreOptionsAvailable = (props: IMoreOptionsAvailableProps) => {
  const { variantCount, goToProduct, product } = props
  return variantCount > 1 ? (
    <div className={css.moreOptionsLinkContainer} tabIndex={0}>
      <div className={css.moreOptionsLink} onClick={() => goToProduct(product)}>
        More options available
      </div>
    </div>
  ) : (
    <></>
  )
}

export default MoreOptionsAvailable
