import * as React from 'react'
import css from './ProductDetailsRow.scss'
import { IStoreLocationWithDistance } from '../../../StoreLocator/StoreLocator'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isCustomProduct } from '../../../Utils/customProductUtils'
import { IProduct } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/PDP'

interface IProductAvailabilityProps {
  customerMessage: string
  myClosestStore?: IStoreLocationWithDistance | null
  showLocationSelect?: boolean
  setShowLocationSelect?: (locationSelect: boolean) => void
}

export const ProductAvailability = (props: IProductAvailabilityProps) => {
  const { customerMessage, myClosestStore, showLocationSelect, setShowLocationSelect } = props

  const toggleLocationSelect = () => {
    setShowLocationSelect && setShowLocationSelect(!showLocationSelect)
  }

  return (
    <div className={css.availability}>
      {myClosestStore && customerMessage && <b>{customerMessage}</b>}
      {myClosestStore === null && (
        <b>
          Please <a onClick={toggleLocationSelect}>update your location</a> for accurate delivery information.
        </b>
      )}
    </div>
  )
}

export const renderIncludesAndMeasureWarningMessage = (targetProduct: IProduct, doNotShowWarning?: boolean) => {
  const warningMessage = isCustomProduct(targetProduct)
    ? 'The furniture you have designed is being manufactured specifically for your home. Please be confident in your decision prior to making your purchase'
    : "Don't forget to measure your space and all doorways, hallways and paths before ordering!"
  return (
    <div>
      {targetProduct?.description && <div className={css.summary}>{'INCLUDES ' + targetProduct.description}</div>}

      {!doNotShowWarning && (
        <div className={css.measureWarningMessage}>
          <div>
            <FontAwesomeIcon icon={faInfoCircle} className={css.infoIcon} aria-label="Information" />
          </div>
          <div className={css.warningMessage}>{warningMessage}</div>
        </div>
      )}
    </div>
  )
}
