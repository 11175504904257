import React, { RefObject, useEffect, useRef, useState } from 'react'
import css from './ProductDisplay.scss'
import MorPriceDisplay from '../../Layout/PDP/MorPriceDisplay/MorPriceDisplay'
import { fit } from '../../../../utils/imageProxy'
import {
  clearDataLayerEcommerce,
  DATA_LAYER_LIST_NAME,
  DATA_LAYER_LOCATION_ID,
  onProductClick,
  onViewItemsList,
} from '../../../../utils/dataLayerController'
import { getCurrentProductFromVariants } from '../../../Utils/productUtils'
import MoreOptionsAvailable from '../../Layout/Search/MoreOptionsAvailable'
import { IUnbxdProduct } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/unbxd/unbxdInterfaces'
import uuid from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/uuid'
import useOnScreen from '../../../Utils/hooks/useOnScreen'
import CustomProductBadge from '../../Layout/Search/CustomProductBadge'
import { IMorUnbxdProduct } from '../../../Utils/unbxdUtils'

export interface ISort {
  attribute: string
  value: string
}

export interface IProductDisplayProps {
  products: any
  triggerUnbxdEvent: (product: IUnbxdProduct, index: number) => void
  title: string
  itemsShown?: number
  pageName: string
  sortVariantsBy?: ISort
}

const ProductDisplayContainer = (props: IProductDisplayProps) => {
  const { products, title, itemsShown, triggerUnbxdEvent, pageName, sortVariantsBy } = props
  const [viewItemsListEventLoaded, setViewItemsListEventLoaded] = useState(false)

  const containerRef: RefObject<HTMLDivElement> = useRef(null)
  const containerVisible = useOnScreen(containerRef)

  useEffect(() => {
    if (containerVisible && !viewItemsListEventLoaded) {
      setViewItemsListEventLoaded(true)
      const eventId = uuid()
      clearDataLayerEcommerce()
      onViewItemsList(
        props.products.slice(0, itemsShown || 20),
        DATA_LAYER_LIST_NAME.PRODUCT_CAROUSEL,
        eventId,
        pageName,
      )
    }
  }, [containerVisible])

  const getDefaultThumbnail = (product: any): string => {
    return fit(product.thumbnail || '/static/morPlaceholder.jpg', { size: 300 })
  }

  const goToProduct = (product: any, index: number) => {
    clearDataLayerEcommerce()
    onProductClick(product, index, DATA_LAYER_LIST_NAME.PRODUCT_CAROUSEL, pageName)
    triggerUnbxdEvent(product, index)
    if (product.productUrl) {
      location.href = product.productUrl
    }
  }

  let productArray = products
  if (itemsShown) {
    productArray = products.slice(0, itemsShown)
  }

  if (products) {
    return (
      <div ref={containerRef}>
        <h2 className={css.title} tabIndex={0}>
          {title}
        </h2>
        <div className={css.container}>
          {productArray && productArray.length ? (
            productArray.map((p: any, index: number) => {
              const product = p?.variants ? getCurrentProductFromVariants(p.variants, sortVariantsBy) : p
              return (
                <div className={css.productThumbnail} key={index}>
                  <div className={css.thumbnailInner}>
                    <div onClick={() => goToProduct(product, index)}>
                      <div
                        className={css.imageContainer}
                        style={{
                          backgroundImage: `url(${getDefaultThumbnail(product)})`,
                        }}
                        role="img"
                        aria-label={product.thumbnailAlt || product.name}
                        tabIndex={0}
                      />
                      <div className={css.thumbnailBadges}>
                        <CustomProductBadge
                          containerClassName={css.badgeContainer}
                          value={(product as IMorUnbxdProduct).DELIVERY_QUALIFICATION}
                        />
                        <CustomProductBadge
                          containerClassName={css.badgeContainer}
                          value={(product as IMorUnbxdProduct).VENDOR_TYPE}
                        />
                      </div>
                      <div className={css.thumbnailDetails}>
                        <div className={css.thumbnailTitle}>
                          <div>{product.name}</div>
                        </div>
                        <MorPriceDisplay
                          product={product}
                          justifyCenter={pageName === DATA_LAYER_LOCATION_ID.HOMEPAGE_RECS_CAROUSEL}
                          showSeePriceInCartLabel
                        />
                      </div>
                      <MoreOptionsAvailable
                        variantCount={p.variantCount}
                        goToProduct={() => goToProduct(product, index)}
                        product={product}
                      />
                    </div>
                  </div>
                </div>
              )
            })
          ) : (
            <div />
          )}
        </div>
      </div>
    )
  }
  return <></>
}

export default ProductDisplayContainer
