import * as React from 'react'
import css from './CustomProductBadge.scss'

export interface ICustomProductBadgeProps {
  value?: string
  style?: any
  containerClassName?: any
}

const CustomProductBadge = (props: ICustomProductBadgeProps) => {
  const { style, containerClassName, value } = props

  const getLabel = () => {
    if (value === 'FREEWHITEGLOVE') {
      return 'Free Delivery'
    }
    if (value === 'FREESHIP') {
      return 'Free Shipping'
    }
    if (value === 'Endless Aisle') {
      return 'Online Only'
    }
    return ''
  }

  return getLabel().length ? (
    <div className={containerClassName || ''} tabIndex={0}>
      <div style={style || {}} className={css.badge}>
        {getLabel()}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default CustomProductBadge
