/* eslint-disable @typescript-eslint/camelcase */
import { IProduct } from '../../components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Interfaces/Strapi/Product'
import { ENVS, envIs } from '../envUtils'

export interface IEventFields {
  pageType?: string
  widget?: string
  pids?: (string | undefined)[]
  pid?: string
  variantId?: string
  box_type?: string
  facets?: { [x: string]: any }
  qty?: any
  prank?: any
  page?: string
  query?: any
  feed?: any
  items?: Array<{ product: IProduct & { parentId?: string }; quantity: number }>
  requestId?: any
}

export enum PageType {
  HOME = 'HOME',
  PRODUCT = 'PRODUCT',
}

export enum Widget {
  WIDGET1 = 'WIDGET1',
  WIDGET2 = 'WIDGET2',
  WIDGET3 = 'WIDGET3',
}

interface IUnbxdEvent {
  experience_pagetype?: string
  experience_widget?: string
  pids_list?: any[string]
  pid?: string
  qty?: any
  variantId?: string
  prank?: number
  pr?: string
  box_type?: string
  query?: string
  feed?: string
  requestid?: string
  page?: string
  page_type?: string
  facets?: any
  requestId?: string
  price?: string
}

interface IUnbxd {
  track: (type: string, obj: { [x: string]: any }) => void
}

const onUnbxdOrder = ({ items }: IEventFields) => {
  if (items) {
    const Unbxd: IUnbxd | null = getUnbxd()
    for (const item of items) {
      const uniqueId: string = item.product.parentId || item.product.sku
      const variantId: string = item.product.sku
      const quantity: string = item.quantity.toString()
      const price: string = (item.product as any).finalPrice_XX.toString()

      _UnbxTrack({
        label: 'order',
        body: {
          pid: uniqueId,
          qty: quantity,
          variantId,
          price,
        },
        unbxd: Unbxd,
      })
    }
  }
}

const onUnbxdRemoveFromCart = (uniqueId: string, sku: string, qty: string) => {
  _UnbxTrack({
    label: 'cartRemoval',
    body: {
      pid: uniqueId,
      variantId: sku,
      qty,
    },
  })
}

const onUnbxdAddToCart = (uniqueId: string, variantId: string, qty: string, requestId: string) => {
  _UnbxTrack({
    label: 'addToCart',
    body: {
      pid: uniqueId,
      variantId,
      qty,
      requestId,
    },
  })
}

// ---------------------------- scripts ----------------------------------------
export interface IUnbxdWindow {
  Unbxd: any
  UnbxdQueue: [{ event: string; opts: IUnbxdEvent }]
  flushUnbxdQueue(): any
}

/**
 * globals for unbxd events.
 * */
const getWindow = (): IUnbxdWindow | null => {
  if (process.browser && window) {
    const win: any = window as any
    if (win) {
      win.Unbxd = win.Unbxd || {}
      win.UnbxdQueue = win.UnbxdQueue || []
      win.flushUnbxdQueue = flushUnbxdQueue
      return win
    }
  }
  return null
}

/**
 * sends the event to unbxd if the external script has been loaded,
 * if it is not loaded it will keep it in memory
 * */

function trackEvent(event: string, opts: IUnbxdEvent) {
  try {
    const win = getWindow()
    if (!win) {
      return
    }
    if (win?.Unbxd?.track) {
      win.Unbxd.track(event, opts)
    } else {
      win.UnbxdQueue.push({ event, opts })
    }
  } catch (e) {
    console.error(e)
  }
}

const _UnbxTrack = ({ label, body, unbxd }: { label: string; body?: any; unbxd?: IUnbxd | null }) => {
  const Unbxd: IUnbxd | null = unbxd ?? getUnbxd()

  if (Unbxd?.track) {
    Unbxd.track(label, body)
  }

  if (envIs([ENVS.LOCAL])) {
    // just for local env
    console.log('Unbxd tracking', { label, body })
  }
}

const getUnbxd = (): IUnbxd | null => {
  if (window) {
    const win: any = window as any
    const Unbxd = win.Unbxd
    return retryToFindUnbxd(Unbxd, 0)
  }
  return null
}

const retryToFindUnbxd = (Unbxd: IUnbxd, counter: number): IUnbxd | null => {
  if (Unbxd) {
    return Unbxd
  } else {
    if (counter > 4) {
      return null
    }
    setTimeout(() => {
      retryToFindUnbxd(Unbxd, ++counter)
    }, 100)
    return null
  }
}

/**
 * generator wrapping the UnbxdQueue
 * */
function* unbxdTasks(): Iterable<{ event: string; opts: IUnbxdEvent }> {
  const win = getWindow()
  if (!win) {
    return
  }

  while (win.UnbxdQueue.length > 0) {
    const i = win.UnbxdQueue.shift()
    if (!i) {
      continue
    }
    yield { event: i.event, opts: i.opts }
  }
}

function onRecommendationsWidgetImpression(pageType: PageType, widget: Widget, pids: string[]) {
  const _pids = pids?.filter(i => i !== null)
  _UnbxTrack({
    label: 'experience_impression',
    body: {
      experience_pagetype: pageType,
      experience_widget: widget,
      pids_list: _pids,
    },
  })
}

function onUnbxdProductClick(
  pid: string,
  pr: string,
  requestId?: string,
  experience_pagetype?: string,
  experience_widget?: string,
) {
  _UnbxTrack({
    label: 'click',
    body: {
      pid,
      pr,
      experience_pagetype,
      experience_widget,
      requestId,
    },
  })
}

/**
 * called after the unbxd script is loaded to
 * send any events to unbxd that were already fired before
 * the script was ready
 * */
async function flushUnbxdQueue() {
  const win = getWindow()
  if (!win) {
    return
  }
  const g = unbxdTasks()
  for await (const { event, opts } of g) {
    trackEvent(event, opts)
  }
}

export { onUnbxdOrder, onRecommendationsWidgetImpression, onUnbxdProductClick, onUnbxdRemoveFromCart, onUnbxdAddToCart }
