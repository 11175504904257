import * as React from 'react'
import css from './MorPriceDisplay.scss'
import LocationContext from '../../../Context/LocationContext'
import { getStoreCode } from '../../../../Utils/getStoreCodeFromCookie'
import { toFixedDec } from '../../../../Utils/priceFormatter'
import { ILowestHighestFinalPrice } from '../../../../Utils/productUtils'
import { IDeliveryMethodCookieType } from '../../../Context/MorCartContext'
import { getCookie } from '../../../../../utils/cookie'

export interface IPriceDisplayProps {
  product: any
  quantity?: number
  className?: string
  showWasLabel?: boolean
  justifyCenter?: boolean
  storeCode?: string
  showSeePriceInCartLabel?: boolean
  lowestHighestFinalPrice?: ILowestHighestFinalPrice | null
}

export default class MorPriceDisplay extends React.Component<IPriceDisplayProps> {
  static contextType = LocationContext.Context
  public renderPriceDisplay = () => {
    const { product, quantity, showWasLabel, storeCode, showSeePriceInCartLabel, lowestHighestFinalPrice } = this.props
    const pickupStoreCode = getCookie(IDeliveryMethodCookieType.PICKUP_STORE_CODE)
    let currentStoreCode = pickupStoreCode && pickupStoreCode !== 'null' ? pickupStoreCode : storeCode || getStoreCode()
    if (this.context.myClosestStore) {
      currentStoreCode = this.context.myClosestStore.legacyStoreId
    }
    let salePrice =
      currentStoreCode && product[`salePrice_${currentStoreCode}`] ? product[`salePrice_${currentStoreCode}`] : null
    let price = currentStoreCode && product[`price_${currentStoreCode}`] ? product[`price_${currentStoreCode}`] : null
    const isMapEnforced: boolean = (product.mapEnforced as any) === 'true'
    if (quantity) {
      salePrice = salePrice * quantity
      price = price * quantity
    }

    if (isMapEnforced && product.map) {
      return (
        <div>
          <span className={`${this.props.className || showSeePriceInCartLabel ? css.blue : css.black}`}>
            {showSeePriceInCartLabel ? <b>See price in cart</b> : toFixedDec(product[`finalPrice_${currentStoreCode}`])}
          </span>
        </div>
      )
    }

    if (lowestHighestFinalPrice?.lowestFinalPrice) {
      return (
        <div className={css.black}>
          <span>As low as: </span>
          <span>{toFixedDec(lowestHighestFinalPrice.lowestFinalPrice)}</span>
        </div>
      )
    }

    if (salePrice && price) {
      return (
        <div>
          <span className={css.blue}>{toFixedDec(salePrice)} </span>
          {showWasLabel && <span className={css.wasLabel}>Was: </span>}
          <span className={css.crossedOut}>{toFixedDec(price)}</span>
        </div>
      )
    }

    return (
      <div>
        <span className={`${this.props.className || css.black}`}>{toFixedDec(price)}</span>
      </div>
    )
  }
  public render() {
    return (
      <div className={`${css.core} ${this.props.justifyCenter ? css.center : ''}`}>{this.renderPriceDisplay()}</div>
    )
  }
}
